(() => {
    document.querySelectorAll('.modal-button').forEach(function(button) {
    button.addEventListener('click', function() {
      var targetId = button.getAttribute('data-target');
      document.querySelector('#' + targetId).classList.add('is-active');
    });
  });
  
  document.querySelectorAll('.sgds-modal-card-head > .delete').forEach(function(button) {
    button.addEventListener('click', function() {
      document.querySelectorAll('.sgds-modal').forEach(function(modal) {
        modal.classList.remove('is-active');
      });
    });
  });
  
  document.querySelectorAll('.sgds-modal-card-foot > .is-text').forEach(function(button) {
    if (button.textContent.includes('Cancel')) {
      button.addEventListener('click', function() {
        document.querySelectorAll('.sgds-modal').forEach(function(modal) {
          modal.classList.remove('is-active');
        });
      });
    }
  });
})();
